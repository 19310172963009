<template>
    <div>
        <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
        <ListPage ref="appTable" :endpoint="`${apiCall}?variant=parentOnly&sort=+_id`" :headers="headers"
            title="Products" :showActionsColumn="false" :showAddButton="isAllowed('createProduct')"
            :showEditButton="isAllowed('editProduct')" :showDeleteButton="isAllowed('deleteProduct')"
            :showViewButton="isAllowed('viewProduct')" @view="navigateTo(`/app/products/view/${$event._id}`)"
            @add="navigateTo('/app/products/0')" @edit="editProduct($event)" @delete="deleteProduct($event._id)">
            <!-- Header Section -->
            <template v-slot:extended-action-buttons>
                <v-btn v-if="isAllowed('createProduct')" @click="navigateTo('/app/products/productBundle/0')"
                    :style="theme" class="ml-2">
                    <v-icon>mdi-package-variant-plus</v-icon>
                </v-btn>
                <import-file-button importData='products' class="ml-2"></import-file-button>
            </template>
            <!-- Mobile View -->
            <template v-slot:item="{ item }" v-if="isMobile">
                <product-card :product="item" :icons="icons" @action="checkIcons"></product-card>
                <v-divider></v-divider>
            </template>
            <!-- Web / Tablet View -->
            <template v-slot:images="{ value }" v-if="isTabletAndUp">
                <video-player v-if="value[0] && value[0]?.mimetype.startsWith('video/')" :src="value[0].path"
                    height="64" width="64"></video-player>
                <image-card v-else :src="value.length > 0 ? value[0]?.path : undefined" height="64px"
                    width="64px"></image-card>
            </template>
            <template v-slot:brand="{ value }" v-if="isTabletAndUp">
                <label>{{ value }}</label>
            </template>
            <template v-slot:variantsAvailable="{ item, value }" v-if="isTabletAndUp">
                <label>{{ item.hasVariant ? value.length : 'NA' }}</label>
            </template>
            <template v-slot:bestDeal="{ value }" v-if="isTabletAndUp">
                <label>{{ value ? 'Yes' : 'No' }}</label>
            </template>
            <template v-slot:type="{ value }" v-if="isTabletAndUp">
                <v-chip small>{{ value }}</v-chip>
            </template>
            <template v-slot:active="{ value }" v-if="isTabletAndUp">
                <v-chip small label :class="value ? 'status-active' : 'status-disabled'">{{ value ? 'Active' :
                    'Disabled' }}</v-chip>
            </template>
        </ListPage>
        <v-alert v-if="msg" :type="msgType">{{ msg }}</v-alert>
    </div>
</template>

<script>
import ActionButton from '@/components/ActionButton'
import appConstants from '@/utils/appConstants'
import ProductCard from '@/components/ProductResponsiveCard'
import ImportFileButton from '@/components/ImportFileButton'
import ListPage from '../../../components/common/ListPage.vue'
import ImageCard from '../../../components/ImageCard.vue'
export default {
    components: {
        ProductCard,
        ImportFileButton,
        ListPage,
        ImageCard
    },
    data() {
        return {
            headers: [{
                text: 'Image',
                value: 'images'
            }, {
                text: 'Code',
                value: 'code'
            },
            {
                text: 'Title',
                value: 'title'
            },
            {
                text: 'Variants',
                value: 'variantsAvailable',
                align: 'right'
            },
            {
                text: 'Brand',
                value: 'brand.displayName'
            },
            {
                text: 'Price',
                value: 'price',
                align: 'right'
            },
            {
                text: 'Offer Price',
                value: 'offerprice',
                align: 'right'
            },
            {
                text: "Active",
                value: 'active'
            },
            {
                text: 'Best Deal',
                value: 'bestDeal',
            },
            {
                text: 'Display Order',
                value: 'displayOrder',
                align: 'right'
            },
            {
                text: 'Type',
                value: 'type'
            },
            {
                text: 'Action',
                value: 'action',
                align: 'right'
            }
            ],
            items: [],
            brands: [],
            icons: [{
                name: "mdi-eye",
                path: "/app/products/view/",
                permission: "viewProduct"
            }, {
                name: "mdi-pencil",
                path: "/app/products/",
                permission: "editProduct"
            },
            {
                name: "mdi-delete",
                permission: "deleteProduct"
            }
            ],
            loading: false,
            apiCall: appConstants.PRODUCTS_API
        }
    },
    methods: {
        async deleteProduct(id) {
            try {
                await this.deleteItem("Are you sure you want to delete this Product?", appConstants.PRODUCTS_API + "/" + id)
                this.items.splice(this.items.findIndex(rec => rec._id == id), 1)
                this.$refs.appTable.loadData()
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data.details
            }
        },
        checkIcons(icon, item) {
            if (icon.name == 'mdi-delete') {
                this.deleteProduct(item._id)
                this.$refs.appTable.loadData()
            }
            else if (item.type == 'BUNDLE')
                this.$router.push(icon.path + 'productBundle/' + item._id)
            else
                this.$router.push(icon.path + '' + item._id)
        },
        editProduct(item) {
            if (item.type == 'BUNDLE') {
                this.navigateTo(`/app/products/productBundle/${item._id}`)
            } else {
                this.navigateTo(`/app/products/${item._id}`)
            }
        }
    }
}
</script>

<style scoped>
.status-active {
    background-color: green !important;
    color: white !important;
    font-weight: 700;
}

.status-disabled {
    background-color: gray !important;
    color: white !important;
    font-weight: 700;
}
</style>
